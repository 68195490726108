import { User } from 'oidc-client-ts'
import { useAuthStore } from './useAuthStore'

import AuthUser = models.auth.authState.AuthUser

export async function useAuthenticate() {
  const isAuthenticated = ref(false)

  if (process.server) return { isAuthenticated }

  const store = useAuthStore()
  // Running Auth in mounted because that is when the oidc plugin becomes available and oidc internal storage gets updated
  window.addEventListener('vuexoidc:accessTokenExpired', () => {
    // This will force log in when token expires
    isAuthenticated.value = false
    return store.dispatch('auth/authenticateOidc')
  })
  let user: typeof User | null = null
  if (!store.getters['auth/oidcIsAuthenticated']) {
    // Get user from oidc storage - handles full refresh
    user = await store.dispatch('auth/getOidcUser')
    if (!user) {
      // Authenticate user
      store.dispatch('auth/authenticateOidc')
    } else {
      // Store user in oidc storage
      await store.dispatch('auth/storeOidcUser', user)
      // Check if user is authenticated
      if (!store.getters['auth/oidcIsAuthenticated']) {
        // Authenticate user
        store.dispatch('auth/authenticateOidc')
      }
    }
  }
  // Update flag to map latest oidc storage state
  isAuthenticated.value = store.getters['auth/oidcIsAuthenticated']
  return { isAuthenticated }
}

export async function useAuthState() {
  const store = useAuthStore()
  const isLoggingOut = ref(false)

  // force load of current authentication state
  if (!store.getters['auth/oidcIsAuthenticated']) {
    // Get user from oidc storage - handles full refresh
    const usr = await store.dispatch('auth/getOidcUser')
    if (usr) {
      // Store user in oidc storage
      await store.dispatch('auth/storeOidcUser', usr)
    }
  }

  const isAuthenticated = computed<boolean>(
    () => store.getters['auth/oidcIsAuthenticated']
  )
  const user = computed<AuthUser>(() => store.getters['auth/oidcUser'])

  const logout = async () => {
    isLoggingOut.value = true
    await store.dispatch('auth/signOutOidc')
    isLoggingOut.value = false
  }

  return { user, isAuthenticated, logout, isLoggingOut }
}
